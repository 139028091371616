define(['santa-components', 'zepto', 'lodash', 'prop-types', 'create-react-class', 'coreUtils', 'verticalAnchorsMenu/utils/svgShapesData'], function (santaComponents, $, _, PropTypes, createReactClass, coreUtils, svgShapesData) {
    'use strict';

    const ELEMENT_CLASSES = {
        item: 'item',
        line: 'line',
        link: 'link',
        symbol: 'symbol',
        textWrapper: 'text-wrapper',
        label: 'label'
    };

    return createReactClass({
        displayName: 'VerticalAnchorsMenuItem',

        propTypes: {
            anchorLink: PropTypes.shape({}).isRequired,
            anchorData: PropTypes.shape({
                name: PropTypes.string,
                id: PropTypes.string
            }).isRequired,
            browser: PropTypes.shape({
                ie: PropTypes.bool,
                version: PropTypes.string
            }),
            styleId: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            isTablet: PropTypes.bool.isRequired,
            primaryPageId: PropTypes.string.isRequired,
            backgroundBrightness: PropTypes.number,
            colorBrightness: PropTypes.number,
            hoveredOrSelectedColorBrightness: PropTypes.number,
            svgShapeName: PropTypes.string.isRequired,
            hoverElementClass: PropTypes.string,
            isActive: PropTypes.bool
        },

        getInitialState() {
            this.elementClasses = {};
            this.anchorLabelId = `anchor-label_${this.props.anchorData.id}`;
            return {
                hovered: null
            };
        },

        updateElementClasses() {
            const styleId = this.props.styleId;
            _.forEach(ELEMENT_CLASSES, function (value, key) {
                this.elementClasses[key] = `${styleId}_${value}`;
            }.bind(this));
        },

        handleOnMouseEnter() {
            if (!this.props.isTablet) {
                this.setState({hovered: true});
            }
        },

        handleOnMouseLeave() {
            if (!this.props.isTablet) {
                this.setState({hovered: false});
            }
        },

        disableClickOnTextWrapper(e) {
            const $textWrapper = $(e.target).find(`[class=${this.elementClasses.textWrapper}]`);
            if ($textWrapper && $textWrapper.css('visibility') === 'hidden') {
                e.preventDefault();
                e.stopPropagation();
            }
        },

        getLabelProps() {
            return {
                id: this.anchorLabelId,
                className: this.elementClasses.label
            };
        },

        getTextWrapperProps() {
            return {
                className: this.elementClasses.textWrapper,
                key: 'textWrapper'
            };
        },

        getSvgProps() {
            const svgShape = svgShapesData[this.props.svgShapeName];
            const svgClass = this.elementClasses.symbol;
            const svgProps = _.clone(svgShape.svg);

            _.assign(svgProps, {
                key: `img-${svgClass}${this.props.svgShapeName}`,
                className: svgClass,
                dangerouslySetInnerHTML: {__html: svgShape.content}
            }
            );

            return svgProps;
        },

        getLinkProps() {
            const linkProps = {
                className: this.elementClasses.link,
                'aria-labelledby': this.anchorLabelId
            };

            // Workaround for IE10 (Pointer Events are not supported) - http://caniuse.com/#feat=pointer-events
            const isIE10 = this.props.browser.ie && this.props.browser.version <= 10;
            if (this.props.disabled && isIE10) {
                linkProps.onClick = this.disableClickOnTextWrapper;
            }

            _.assign(linkProps, this.props.anchorLink);

            return linkProps;
        },

        getItemProps(key) { // eslint-disable-line complexity
            const styleId = this.props.styleId;
            let itemClass = this.elementClasses.item;

            if (this.props.isActive) {
                itemClass += ` ${styleId}_active`;
            }
            if (!this.props.isTablet && this.state.hovered) {
                itemClass += ` ${styleId}_hover`;
            }

            const BRIGHTNESS_MID = 50;
            const colorBrightness = this.state.hovered ? this.props.hoveredOrSelectedColorBrightness : this.props.colorBrightness;
            const backgroundBrightness = this.props.backgroundBrightness;
            const areBrightnessValuesNubmers = _.isFinite(colorBrightness) && _.isFinite(backgroundBrightness);
            const isBrightnessDiffSmallerThanThreshold = Math.abs(colorBrightness - backgroundBrightness) < coreUtils.siteConstants.BRIGHTNESS_DIFF_THRESHOLD;

            if (areBrightnessValuesNubmers && isBrightnessDiffSmallerThanThreshold) {
                if (backgroundBrightness >= BRIGHTNESS_MID) {
                    itemClass += ` ${styleId}_dark`;
                } else {
                    itemClass += ` ${styleId}_light`;
                }
            }

            return {
                key,
                className: itemClass,
                onMouseLeave: this.handleOnMouseLeave
            };
        },

        addMouseEnterEventToSkinExportedClass(elementProps) {
            _.some(elementProps, function (props) {
                if (props.className && !this.props.isTablet && _.includes(props.className, this.props.hoverElementClass)) {
                    props.onMouseEnter = this.handleOnMouseEnter;
                    return true;
                }
            }.bind(this));
        },

        render() {
            this.updateElementClasses();

            const elementProps = {
                itemProps: this.getItemProps(this.props.anchorData.id),
                linkProps: this.getLinkProps(),
                svgProps: this.getSvgProps(),
                textWrapperProps: this.getTextWrapperProps(),
                labelProps: this.getLabelProps()
            };

            this.addMouseEnterEventToSkinExportedClass(elementProps);

            return santaComponents.utils.createReactElement(
                'li',
                elementProps.itemProps,
                santaComponents.utils.createReactElement('a', elementProps.linkProps, [
                    santaComponents.utils.createReactElement('svg', elementProps.svgProps),
                    santaComponents.utils.createReactElement(
                        'span',
                        elementProps.textWrapperProps,
                        santaComponents.utils.createReactElement('span', elementProps.labelProps, this.props.anchorData.name)
                    )
                ])
            );
        }
    });
}
);
