define([
    'santa-components',
    'reactDOM',
    'lodash',
    'create-react-class',
    'verticalAnchorsMenu/bi/events',
    'verticalAnchorsMenu/skins/skins.json',
    'verticalAnchorsMenu/components/verticalAnchorsBaseMenu',
    'verticalMenu'
],
function (
    santaComponents,
    ReactDOM,
    _,
    createReactClass,
    biEvents,
    skinsJson,
    verticalAnchorsBaseMenu,
    verticalMenu
) {
    'use strict';

    const getCompCss = (styleId, props, styleClass = styleId) => {
        const {
            getStyleData,
            themeData,
            siteZoomRatio,
            invertedZoomRatio,
            orientationZoomFix,
            mobileZoom,
            scriptsLocationMap
        } = props;

        const styleData = getStyleData(styleId);
        const mobileData = {siteZoomRatio, invertedZoomRatio, orientationZoomFix, mobileZoom};
        const serviceTopology = {scriptsLocationMap};
        const getSkin = skinId => skinsJson[skinId];
        const skinData = getSkin(styleData.skin);
        const styleProps = _.get(styleData, 'style.properties', {});

        return skinData ?
            {[styleId]: santaComponents.utils.skinsRenderer.createSkinCss(skinData, styleProps, themeData, styleClass, mobileData, serviceTopology, getSkin)} :
            null;
    };

    const cssTypes = {
        getStyleData: santaComponents.santaTypesDefinitions.Component.getStyleData,
        themeData: santaComponents.santaTypesDefinitions.Theme.THEME_DATA,
        siteZoomRatio: santaComponents.santaTypesDefinitions.Mobile.siteZoomRatio,
        invertedZoomRatio: santaComponents.santaTypesDefinitions.Mobile.invertedZoomRatio,
        orientationZoomFix: santaComponents.santaTypesDefinitions.Mobile.orientationZoomFix,
        mobileZoom: santaComponents.santaTypesDefinitions.Mobile.mobileZoom,
        scriptsLocationMap: santaComponents.santaTypesDefinitions.ServiceTopology.scriptsLocationMap
    };

    getCompCss.cssTypes = cssTypes;

    const getCompFonts = (styleIds, {getStyleData, generalTheme}) => {
        const styleItems = getStyleData ? _.reduce(styleIds, (result, styleId) => _.assign(result, {[styleId]: getStyleData(styleId)}), {}) : styleIds;
        const getSkin = skinId => skinsJson[skinId];

        return santaComponents.utils.collectFontsFromLoadedCompStyles(styleItems, generalTheme, getSkin);
    };

    const fontsTypes = {
        getStyleData: santaComponents.santaTypesDefinitions.Component.getStyleData,
        generalTheme: santaComponents.santaTypesDefinitions.Theme.THEME_DATA
    };

    getCompFonts.fontsTypes = fontsTypes;

    const ANCHOR_BI_DELAY_SECONDS = [3, 6, 10];

    // TODO: This component is desktop only, so we'll fix this variable later
    // It saves state for all component instances - so either it should be in an aspect or change the event to per component
    // The event is used by marketing BI
    let anchorBiIds = []; //eslint-disable-line santa/no-module-state

    const verticalAnchorsBaseMenuClass = createReactClass(verticalAnchorsBaseMenu);
    const verticalMenuClass = createReactClass(verticalMenu);

    function resolveMenuElement(menuProps) {
        if (_.includes(menuProps.skin, 'verticalanchorsmenu')) {
            return santaComponents.utils.createReactElement(verticalAnchorsBaseMenuClass, menuProps);
        } else if (_.includes(menuProps.skin, 'verticalmenu')) {
            return santaComponents.utils.createReactElement(verticalMenuClass, menuProps);
        }

        return santaComponents.utils.createReactElement('div', {style: {visibility: 'hidden'}}, ['AnchorMenuSkin Not Found']);
    }

    function isFullCircleSkin(skinName) {
        return _.includes(skinName, 'VerticalAnchorsMenuLinkedNoTextSkin');
    }

    function reportActiveAnchor(reportBI, activeAnchor) {
        if (activeAnchor) {
            _.forEach(anchorBiIds, function (id) {
                clearTimeout(id);
            });
            anchorBiIds = _.map(ANCHOR_BI_DELAY_SECONDS, function (delay) {
                return setTimeout(function () {
                    reportBI(biEvents.TIME_IN_FOLD, {
                        activeFold: activeAnchor.index,
                        totalFolds: activeAnchor.total,
                        timeInFold: delay
                    });
                }, delay * 1000);
            });
        }
    }

    return {
        displayName: 'VerticalAnchorsMenu',

        statics: {
            getCompCss,
            getCompFonts,
            shouldLayout: {
                activeAnchor: false
            }
        },

        propTypes: _.assign({
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            skin: santaComponents.santaTypesDefinitions.Component.skin.isRequired,
            primaryPageId: santaComponents.santaTypesDefinitions.primaryPageId.isRequired,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,
            reportBI: santaComponents.santaTypesDefinitions.reportBI,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            registerLayoutFunc: santaComponents.santaTypesDefinitions.Layout.registerLayoutFunc.isRequired,
            registerToMeanColor: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.registerToMeanColor,
            unregisterToMeanColor: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.unregisterToMeanColor,
            registerToActiveAnchor: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.registerToActiveAnchor,
            unregisterToActiveAnchor: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.unregisterToActiveAnchor,
            getOverlappingBackgroundBrightness: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.getOverlappingBackgroundBrightness,
            activeAnchor: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.activeAnchor
        },
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(verticalAnchorsBaseMenu)
        ),
        getInitialState() {
            this.prevCompProps = _.cloneDeep(this.props.compProp);
            this.activeAnchor = _.cloneDeep(this.props.activeAnchor);
            return {
                overlappingBackgroundBrightness: null
            };
        },

        measureComponent(node, $) {
            const $node = $(node);
            const $anchorLinks = $node.find('[class*=link]');
            const $labels = $node.find('[class*=label]');
            const $symbols = $node.find('[class*=symbol]');
            const $itemsLen = $anchorLinks.length;
            const symbolWidth = $symbols.width();
            const minHeight = Math.ceil($anchorLinks.height() * $itemsLen);
            const labelMaxWidth = _($labels).map(l => $(l).width()).max();
            const minWidth = labelMaxWidth + symbolWidth;

            return [{
                type: 'css', node, changes: {
                    width: Math.max(node.offsetWidth, minWidth),
                    height: Math.max(node.offsetHeight, minHeight),
                    minWidth, minHeight
                }
            }];
        },

        componentDidMount() {
            this.props.registerToActiveAnchor(this);
            this.props.registerLayoutFunc(ReactDOM.findDOMNode(this), this.measureComponent);

            if (this.props.compProp.autoColor) {
                this.props.registerToMeanColor(this.handleBackgroundBrightness);
            }
        },
        componentWillUnmount() {
            this.props.unregisterToActiveAnchor(this);
            this.props.unregisterToMeanColor(this.handleBackgroundBrightness);
        },
        componentWillReceiveProps(nextProps) {
            if (this.prevCompProps.autoColor && !nextProps.compProp.autoColor) {
                nextProps.unregisterToMeanColor(this.handleBackgroundBrightness);
            } else if (nextProps.compProp.autoColor && !this.prevCompProps.autoColor) {
                nextProps.registerToMeanColor(this.handleBackgroundBrightness);
            }

            if (_.get(nextProps.activeAnchor, 'activeAnchorComp.id', '') !== _.get(this.activeAnchor, 'activeAnchorComp.id', '')) {
                this.activeAnchor = _.cloneDeep(nextProps.activeAnchor);
                reportActiveAnchor(this.props.reportBI, nextProps.activeAnchor);
            }
            this.prevCompProps = _.cloneDeep(nextProps.compProp);
        },
        handleBackgroundBrightness() {
            if (this.props.isExperimentOpen('bv_disableAnchorMenuAutoColor')) {
                return;
            }
            const overlappingBackgroundBrightness = this.props.getOverlappingBackgroundBrightness();
            if (this.props.compProp.autoColor && this.state.overlappingBackgroundBrightness !== overlappingBackgroundBrightness) {
                this.setState({
                    overlappingBackgroundBrightness
                });
            }
        },
        isSelected(anchorId) {
            return _.get(this.props.activeAnchor, 'activeAnchorComp.id', '') === anchorId;
        },
        render() {
            const menuProps = _.assign({}, this.props, {
                isSelectedFn: this.isSelected,
                svgShapeName: isFullCircleSkin(this.props.skin) ? 'fullCircle' : 'circle'
            });

            if (this.props.compProp.autoColor && _.get(this, 'props.structure.layout.fixedPosition')) {
                menuProps.overlappingBackgroundBrightness = this.state.overlappingBackgroundBrightness;
            }

            return resolveMenuElement(menuProps);
        }
    };
});
