define(['verticalAnchorsMenu/components/verticalAnchorsMenuComp', 'verticalAnchorsMenu/components/verticalAnchorsMenuItem', 'skins', 'verticalAnchorsMenu/skins/skins.json', 'componentsCore'],
    function (verticalAnchorsMenuComp, verticalAnchorsMenuItem, skinsPackage, skinsJson, componentsCore) {
        'use strict';

        const verticalAnchorsMenu = {
            verticalAnchorsMenuComp,
            verticalAnchorsMenuItem
        };


        componentsCore.compRegistrar
            .register('wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu', verticalAnchorsMenu.verticalAnchorsMenuComp)
            .register('wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenuItem', verticalAnchorsMenu.verticalAnchorsMenuItem);

        skinsPackage.skinsMap.addBatch(skinsJson);

        return verticalAnchorsMenu;
    });
