define(['santa-components', 'lodash', 'prop-types', 'coreUtils', 'componentsCore', 'verticalAnchorsMenu/components/verticalAnchorsMenuItem'], function (santaComponents, _, PropTypes, coreUtils, componentsCore, verticalAnchorsMenuItem) {
    'use strict';

    /**
     * @class components.VerticalAnchorsBaseMenu
     * @extends {core.skinBasedComp}
     */
    return {
        displayName: 'VerticalAnchorsBaseMenu',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.skinInfo],

        propTypes: {
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId.isRequired,
            structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired,
            primaryPageId: santaComponents.santaTypesDefinitions.primaryPageId.isRequired,
            browser: santaComponents.santaTypesDefinitions.Browser.browser.isRequired,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice.isRequired,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo,
            getAnchorLinkItems: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.getAnchorLinkItems.isRequired,
            isSelectedFn: PropTypes.func.isRequired,
            overlappingBackgroundBrightness: PropTypes.number,
            svgShapeName: PropTypes.string,
            getTranslatedAriaLabel: santaComponents.santaTypesDefinitions.Accessibility.getTranslatedAriaLabel.isRequired
        },

        getDefaultProps() {
            return {
                svgShapeName: 'circle'
            };
        },

        getMenuButtons(anchorLinkItems) {
            const skinExports = this.getSkinExports();
            return _.map(anchorLinkItems, function (anchorLinkItem) { //eslint-disable-line react/display-name
                const isActive = this.props.isSelectedFn(anchorLinkItem.id);

                const menuItemProps = {
                    key: anchorLinkItem.id,
                    anchorLink: coreUtils.linkRenderer.renderLink(anchorLinkItem, this.props.linkRenderInfo, this.props.rootNavigationInfo),
                    anchorData: anchorLinkItem,
                    browser: this.props.browser,
                    styleId: this.props.styleId,
                    disabled: skinExports.disableClickOnHiddenElement,
                    isTablet: this.props.isTabletDevice(),
                    primaryPageId: this.props.primaryPageId,
                    svgShapeName: this.props.svgShapeName,
                    hoverElementClass: skinExports.hoverElementClass,
                    isActive
                };

                if (this.props.compProp.autoColor && _.get(this.props.structure, 'layout.fixedPosition')) {
                    const skinParams = this.getParams(['symbolAndTextColor', 'selectedColor']);
                    const mainColor = skinParams.symbolAndTextColor.value;
                    const selectedColor = skinParams.selectedColor.value;
                    const mainColorBrightness = mainColor.values.hsv[2];
                    const selectedColorBrightness = selectedColor.values.hsv[2];

                    menuItemProps.colorBrightness = isActive ? selectedColorBrightness : mainColorBrightness;
                    menuItemProps.hoveredOrSelectedColorBrightness = selectedColorBrightness;
                    menuItemProps.backgroundBrightness = this.props.overlappingBackgroundBrightness;
                }

                return santaComponents.utils.createReactElement(verticalAnchorsMenuItem, menuItemProps);
            }.bind(this));
        },

        addConnectingLinesIfNeeded(menuItems) {
            if (!this.getSkinExports() || this.getSkinExports().hasConnectingLines !== true) {
                return menuItems;
            }
            const itemsAndLines = [];
            _.forEach(menuItems, function (item) {
                itemsAndLines.push(santaComponents.utils.createReactElement('div', {className: `${this.props.styleId}_line`}));
                itemsAndLines.push(item);
            }.bind(this));
            return _.tail(itemsAndLines);
        },

        getMenuClasses() {
            const styleId = this.props.styleId;
            const menuClasses = [];
            menuClasses.push(`${styleId}_orientation-${this.props.compProp.orientation}`);
            menuClasses.push(`${styleId}_text-align-${this.props.compProp.itemsAlignment}`);

            return menuClasses.join(' ');
        },

        getAriaLabel() {
            const MENU_ARIA_LABEL_KEY = 'dropDownMenu_AriaLabel_TopLevel_SiteNavigation';
            return this.props.getTranslatedAriaLabel('AriaLabels', MENU_ARIA_LABEL_KEY, 'Site');
        },

        getSkinProperties() {
            let menuItems = this.getMenuButtons(this.props.getAnchorLinkItems());
            menuItems = this.addConnectingLinesIfNeeded(menuItems);

            return {
                '': {
                    tagName: 'nav',
                    'aria-label': this.getAriaLabel()
                },
                menuContainer: {
                    className: this.getMenuClasses(),
                    children: menuItems
                }
            };
        }
    };
});
