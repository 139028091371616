(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuLinkedNoTextSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {}
    ]
  ],
  "exports": {
    "hoverElementClass": "symbol",
    "hasConnectingLines": true
  },
  "params": {
    "symbolColor": "BG_COLOR_ALPHA",
    "symbolColorSelected": "BG_COLOR_ALPHA",
    "symbolAndTextColor": "BG_COLOR_ALPHA",
    "selectedColor": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "symbolColor": "color_15",
    "symbolColorSelected": "color_2",
    "symbolAndTextColor": [
      "symbolColor"
    ],
    "selectedColor": [
      "symbolColorSelected"
    ]
  },
  "css": {
    "%menuContainer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;width:100%;height:100%;-webkit-user-select:none;-ms-user-select:none;user-select:none;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;align-items:center;width:100%;height:100%;",
    "%menuContainer %_item": "position:relative;height:26px;width:26px;min-height:10px;    position:relative;",
    "%menuContainer %_item %_link": "display:-webkit-box;display:-webkit-flex;display:flex;text-decoration:none;width:100%;cursor:default;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:relative;height:26px;display:inline-block;",
    "%menuContainer %_item %_link %_text-wrapper": "position:relative;white-space:nowrap;text-align:right;line-height:100%;display:none;",
    "%menuContainer %_item %_link %_text-wrapper %_label": "cursor:pointer;",
    "%menuContainer %_item %_link %_symbol": "cursor:pointer;-webkit-box-flex:0;-webkit-flex:0 0 auto;flex:0 0 auto;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;height:26px;width:26px;",
    "%menuContainer%_orientation-left %_item %_link": "-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%menuContainer%_orientation-right %_item %_link": "-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-webkit-flex-direction:row-reverse;flex-direction:row-reverse;",
    "%menuContainer%_text-align-left %_item %_link %_text-wrapper": "text-align:left;",
    "%menuContainer%_text-align-center %_item %_link %_text-wrapper": "text-align:center;",
    "%menuContainer%_text-align-right %_item %_link %_text-wrapper": "text-align:right;",
    "%menuContainer %_line": "display:none;width:1px;height:100%;background-color:[symbolColor];top:10px;position:relative;opacity:.2;",
    "%menuContainer %_item %_link %_symbol circle:nth-child(1)": "stroke:[symbolColor];stroke-width:2;fill:transparent;opacity:0;-webkit-transition:opacity .25s ease;transition:opacity .25s ease;",
    "%menuContainer %_item %_link %_symbol circle:nth-child(2)": "stroke:[symbolColorSelected];stroke-width:2;fill:transparent;",
    "%menuContainer %_item %_link %_symbol circle:nth-child(3)": "fill:[symbolColor];-webkit-transition:all .25s ease;transition:all .25s ease;",
    "%menuContainer %_item%_hover %_link": "cursor:pointer;",
    "%menuContainer %_item%_hover %_symbol circle:nth-child(3)": "opacity:0.7;",
    "%menuContainer %_item%_active %_link": "cursor:pointer;",
    "%menuContainer %_item%_active %_link %_symbol circle:nth-child(1)": "opacity:.75;",
    "%menuContainer %_item%_active %_link %_symbol circle:nth-child(3)": "fill:[symbolColorSelected];",
    "%menuContainer %_item%_light %_link %_symbol circle:nth-child(1),%menuContainer %_active%_item%_light %_link %_symbol circle:nth-child(1)": "stroke:#fff;",
    "%menuContainer %_item%_light %_link %_symbol circle:nth-child(2),%menuContainer %_active%_item%_light %_link %_symbol circle:nth-child(2)": "stroke:#fff;",
    "%menuContainer %_item%_light %_link %_symbol circle:nth-child(3),%menuContainer %_active%_item%_light %_link %_symbol circle:nth-child(3)": "fill:#fff;",
    "%menuContainer %_item%_dark %_link %_symbol circle:nth-child(1),%menuContainer %_active%_item%_dark %_link %_symbol circle:nth-child(1)": "stroke:#000;",
    "%menuContainer %_item%_dark %_link %_symbol circle:nth-child(2),%menuContainer %_active%_item%_dark %_link %_symbol circle:nth-child(2)": "stroke:#000;",
    "%menuContainer %_item%_dark %_link %_symbol circle:nth-child(3),%menuContainer %_active%_item%_dark %_link %_symbol circle:nth-child(3)": "fill:#000;"
  }
}
 skins['wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {}
    ]
  ],
  "exports": {
    "hoverElementClass": "symbol"
  },
  "params": {
    "symbolColor": "BG_COLOR_ALPHA",
    "symbolColorSelected": "BG_COLOR_ALPHA",
    "symbolAndTextColor": "BG_COLOR_ALPHA",
    "selectedColor": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "symbolColor": "color_15",
    "symbolColorSelected": "color_2",
    "symbolAndTextColor": [
      "symbolColor"
    ],
    "selectedColor": [
      "symbolColorSelected"
    ]
  },
  "css": {
    "%menuContainer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;width:100%;height:100%;-webkit-user-select:none;-ms-user-select:none;user-select:none;",
    "%menuContainer %_item": "position:relative;",
    "%menuContainer %_item %_link": "display:-webkit-box;display:-webkit-flex;display:flex;text-decoration:none;width:100%;cursor:default;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_item %_link %_text-wrapper": "position:relative;white-space:nowrap;text-align:right;line-height:100%;display:none;",
    "%menuContainer %_item %_link %_text-wrapper %_label": "cursor:pointer;",
    "%menuContainer %_item %_link %_symbol": "cursor:pointer;-webkit-box-flex:0;-webkit-flex:0 0 auto;flex:0 0 auto;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;fill:transparent;stroke:[symbolColor];stroke-width:4px;",
    "%menuContainer%_orientation-left %_item %_link": "-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%menuContainer%_orientation-right %_item %_link": "-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-webkit-flex-direction:row-reverse;flex-direction:row-reverse;",
    "%menuContainer%_text-align-left %_item %_link %_text-wrapper": "text-align:left;",
    "%menuContainer%_text-align-center %_item %_link %_text-wrapper": "text-align:center;",
    "%menuContainer%_text-align-right %_item %_link %_text-wrapper": "text-align:right;",
    "%menuContainer %_item%_active %_link %_symbol,%menuContainer %_item%_hover %_link %_symbol": "fill:[symbolColorSelected];stroke:[symbolColorSelected];",
    "%menuContainer %_item%_light %_link %_text-wrapper,%menuContainer %_active%_item%_light %_link %_text-wrapper": "color:#fff;",
    "%menuContainer %_item%_light %_link %_symbol,%menuContainer %_active%_item%_light %_link %_symbol": "stroke:#fff;",
    "%menuContainer %_active%_item%_light %_link %_symbol": "fill:#fff;",
    "%menuContainer %_item%_dark %_link %_text-wrapper,%menuContainer %_active%_item%_dark %_link %_text-wrapper": "color:#000;",
    "%menuContainer %_item%_dark %_link %_symbol,%menuContainer %_active%_item%_dark %_link %_symbol": "stroke:#000;",
    "%menuContainer %_active%_item%_dark %_link %_symbol": "fill:#000;"
  }
}
 skins['wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithHiddenTextSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {}
    ]
  ],
  "exports": {
    "hoverElementClass": "symbol",
    "disableClickOnHiddenElement": true
  },
  "params": {
    "textFont": "FONT",
    "symbolColor": "BG_COLOR_ALPHA",
    "symbolColorSelected": "TEXT_COLOR",
    "textSpacing": "PADDING_SIZE",
    "symbolAndTextColor": "BG_COLOR_ALPHA",
    "selectedColor": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "textFont": "font_1",
    "symbolColor": "color_15",
    "symbolColorSelected": "color_2",
    "textSpacing": "14px",
    "symbolAndTextColor": [
      "symbolColor"
    ],
    "selectedColor": [
      "symbolColorSelected"
    ]
  },
  "css": {
    "%menuContainer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;width:100%;height:100%;-webkit-user-select:none;-ms-user-select:none;user-select:none;",
    "%menuContainer %_item": "position:relative;",
    "%menuContainer %_item %_link": "display:-webkit-box;display:-webkit-flex;display:flex;text-decoration:none;width:100%;cursor:default;-webkit-box-align:center;-webkit-align-items:center;align-items:center;pointer-events:none;",
    "%menuContainer %_item %_link %_text-wrapper": "position:relative;white-space:nowrap;text-align:right;line-height:100%;visibility:hidden;opacity:0;left:10px;[textFont]    color:[symbolColor];width:calc(100% - 15px);-webkit-transition:all 0.3s cubic-bezier(0.23, 1, 0.32, 1);transition:all 0.3s cubic-bezier(0.23, 1, 0.32, 1);",
    "%menuContainer %_item %_link %_text-wrapper %_label": "cursor:pointer;",
    "%menuContainer %_item %_link %_symbol": "cursor:pointer;-webkit-box-flex:0;-webkit-flex:0 0 auto;flex:0 0 auto;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;pointer-events:all;fill:transparent;stroke:[symbolColor];stroke-width:4px;",
    "%menuContainer%_orientation-left %_item %_link": "-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%menuContainer%_orientation-right %_item %_link": "-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-webkit-flex-direction:row-reverse;flex-direction:row-reverse;",
    "%menuContainer%_text-align-left %_item %_link %_text-wrapper": "text-align:left;",
    "%menuContainer%_text-align-center %_item %_link %_text-wrapper": "text-align:center;",
    "%menuContainer%_text-align-right %_item %_link %_text-wrapper": "text-align:right;",
    "%menuContainer %_item%_active %_link,%menuContainer %_item%_hover %_link": "pointer-events:all;",
    "%menuContainer %_item%_active %_link %_symbol,%menuContainer %_item%_hover %_link %_symbol": "fill:[symbolColorSelected];stroke:[symbolColorSelected];",
    "%menuContainer %_item%_active %_link %_text-wrapper,%menuContainer %_item%_hover %_link %_text-wrapper": "visibility:visible;opacity:1;left:0;color:[symbolColorSelected];",
    "%menuContainer%_text-align-left %_item %_link %_text-wrapper %_label,%menuContainer%_orientation-left%_text-align-center %_item %_link %_text-wrapper %_label": "padding-left:[textSpacing];",
    "%menuContainer%_text-align-right %_item %_link %_text-wrapper %_label,%menuContainer%_orientation-right%_text-align-center %_item %_link %_text-wrapper %_label": "padding-right:[textSpacing];",
    "%menuContainer %_item%_light %_link %_text-wrapper,%menuContainer %_active%_item%_light %_link %_text-wrapper": "color:#fff;",
    "%menuContainer %_item%_light %_link %_symbol,%menuContainer %_active%_item%_light %_link %_symbol": "stroke:#fff;",
    "%menuContainer %_active%_item%_light %_link %_symbol": "fill:#fff;",
    "%menuContainer %_item%_dark %_link %_text-wrapper,%menuContainer %_active%_item%_dark %_link %_text-wrapper": "color:#000;",
    "%menuContainer %_item%_dark %_link %_symbol,%menuContainer %_active%_item%_dark %_link %_symbol": "stroke:#000;",
    "%menuContainer %_active%_item%_dark %_link %_symbol": "fill:#000;"
  }
}
 skins['wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithTextSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {}
    ]
  ],
  "exports": {
    "hoverElementClass": "link"
  },
  "params": {
    "textFont": "FONT",
    "symbolColor": "TEXT_COLOR",
    "symbolColorSelected": "TEXT_COLOR",
    "textSpacing": "PADDING_SIZE",
    "symbolAndTextColor": "TEXT_COLOR",
    "selectedColor": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "textFont": "font_1",
    "symbolColor": "color_15",
    "symbolColorSelected": "color_2",
    "textSpacing": "14px",
    "symbolAndTextColor": [
      "symbolColor"
    ],
    "selectedColor": [
      "symbolColorSelected"
    ]
  },
  "css": {
    "%menuContainer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;width:100%;height:100%;-webkit-user-select:none;-ms-user-select:none;user-select:none;",
    "%menuContainer %_item": "position:relative;",
    "%menuContainer %_item %_link": "display:-webkit-box;display:-webkit-flex;display:flex;text-decoration:none;width:100%;cursor:default;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_item %_link %_text-wrapper": "position:relative;white-space:nowrap;text-align:right;line-height:100%;[textFont]  color:[symbolColor];width:calc(100% - 15px);-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;",
    "%menuContainer %_item %_link %_text-wrapper %_label": "cursor:pointer;",
    "%menuContainer %_item %_link %_symbol": "cursor:pointer;-webkit-box-flex:0;-webkit-flex:0 0 auto;flex:0 0 auto;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;fill:transparent;stroke:[symbolColor];stroke-width:4px;",
    "%menuContainer%_orientation-left %_item %_link": "-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%menuContainer%_orientation-right %_item %_link": "-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-webkit-flex-direction:row-reverse;flex-direction:row-reverse;",
    "%menuContainer%_text-align-left %_item %_link %_text-wrapper": "text-align:left;",
    "%menuContainer%_text-align-center %_item %_link %_text-wrapper": "text-align:center;",
    "%menuContainer%_text-align-right %_item %_link %_text-wrapper": "text-align:right;",
    "%menuContainer %_item%_active %_link %_symbol,%menuContainer %_item%_hover %_link %_symbol": "fill:[symbolColorSelected];stroke:[symbolColorSelected];",
    "%menuContainer %_item%_active %_link %_text-wrapper,%menuContainer %_item%_hover %_link %_text-wrapper": "color:[symbolColorSelected];",
    "%menuContainer%_text-align-left %_item %_link %_text-wrapper %_label,%menuContainer%_orientation-left%_text-align-center %_item %_link %_text-wrapper %_label": "padding-left:[textSpacing];",
    "%menuContainer%_text-align-right %_item %_link %_text-wrapper %_label,%menuContainer%_orientation-right%_text-align-center %_item %_link %_text-wrapper %_label": "padding-right:[textSpacing];",
    "%menuContainer %_item%_light %_link %_text-wrapper,%menuContainer %_active%_item%_light %_link %_text-wrapper": "color:#fff;",
    "%menuContainer %_item%_light %_link %_symbol,%menuContainer %_active%_item%_light %_link %_symbol": "stroke:#fff;",
    "%menuContainer %_active%_item%_light %_link %_symbol": "fill:#fff;",
    "%menuContainer %_item%_dark %_link %_text-wrapper,%menuContainer %_active%_item%_dark %_link %_text-wrapper": "color:#000;",
    "%menuContainer %_item%_dark %_link %_symbol,%menuContainer %_active%_item%_dark %_link %_symbol": "stroke:#000;",
    "%menuContainer %_active%_item%_dark %_link %_symbol": "fill:#000;"
  }
}
 skins['wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuTextSkin'] = {
  "react": [
    [
      "ul",
      "menuContainer",
      [],
      {}
    ]
  ],
  "exports": {
    "hoverElementClass": "link"
  },
  "params": {
    "textFont": "FONT",
    "symbolColor": "TEXT_COLOR",
    "symbolColorSelected": "TEXT_COLOR",
    "symbolAndTextColor": "TEXT_COLOR",
    "selectedColor": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "textFont": "font_1",
    "symbolColor": "color_15",
    "symbolColorSelected": "color_2",
    "symbolAndTextColor": [
      "symbolColor"
    ],
    "selectedColor": [
      "symbolColorSelected"
    ]
  },
  "css": {
    "%menuContainer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;width:100%;height:100%;-webkit-user-select:none;-ms-user-select:none;user-select:none;",
    "%menuContainer %_item": "position:relative;",
    "%menuContainer %_item %_link": "display:-webkit-box;display:-webkit-flex;display:flex;text-decoration:none;width:100%;cursor:default;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_item %_link %_text-wrapper": "position:relative;white-space:nowrap;text-align:right;line-height:100%;[textFont]  color:[symbolColor];width:100%;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;",
    "%menuContainer %_item %_link %_text-wrapper %_label": "cursor:pointer;",
    "%menuContainer %_item %_link %_symbol": "cursor:pointer;-webkit-box-flex:0;-webkit-flex:0 0 auto;flex:0 0 auto;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;display:none;",
    "%menuContainer%_orientation-left %_item %_link": "-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%menuContainer%_orientation-right %_item %_link": "-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-webkit-flex-direction:row-reverse;flex-direction:row-reverse;",
    "%menuContainer%_text-align-left %_item %_link %_text-wrapper": "text-align:left;",
    "%menuContainer%_text-align-center %_item %_link %_text-wrapper": "text-align:center;",
    "%menuContainer%_text-align-right %_item %_link %_text-wrapper": "text-align:right;",
    "%menuContainer %_item%_active %_link %_text-wrapper,%menuContainer %_item%_hover %_link %_text-wrapper": "color:[symbolColorSelected];",
    "%menuContainer %_item%_light %_link %_text-wrapper,%menuContainer %_active%_item%_light %_link %_text-wrapper": "color:#fff;",
    "%menuContainer %_item%_light %_link %_symbol,%menuContainer %_active%_item%_light %_link %_symbol": "stroke:#fff;",
    "%menuContainer %_active%_item%_light %_link %_symbol": "fill:#fff;",
    "%menuContainer %_item%_dark %_link %_text-wrapper,%menuContainer %_active%_item%_dark %_link %_text-wrapper": "color:#000;",
    "%menuContainer %_item%_dark %_link %_symbol,%menuContainer %_active%_item%_dark %_link %_symbol": "stroke:#000;",
    "%menuContainer %_active%_item%_dark %_link %_symbol": "fill:#000;"
  }
}

        return skins;
    }));